<template>
  <div class="liveListBox">
    <pageTitle pageTitle="直播列表">
      <div slot="content">
        1.热度：创建至今，进入观看页面（直播和回放、点播）的浏览量
        <br />
        2.控制台数据为真实数据，不统计虚拟数据
        <br />
        3.定时直播不支持发起直播，不支持在微吼直播APP的活动列表展示
        <br />
        4.分组直播不支持客户端及APP端发起直播，不支持在微吼直播APP的活动列表展示
      </div>
    </pageTitle>
    <!-- 操作栏  -->
    <div class="operaBox" v-if="totalElement || isSearch">
      <vh-dropdown
        v-if="vodPerssion == 1 || isTiming == 1"
        @command="createLiveAction"
        trigger="click"
      >
        <vh-button type="primary" round size="medium" class="create_but_padding">
          <i class="vh-icon-plus"></i>
          创建直播/点播
        </vh-button>
        <vh-dropdown-menu slot="dropdown" class="dropdown_width">
          <vh-dropdown-item
            type="primary"
            round
            command="1"
            v-preventReClick
            size="medium"
            class="length104"
          >
            创建直播
          </vh-dropdown-item>
          <vh-dropdown-item
            size="medium"
            round
            command="2"
            v-if="vodPerssion == 1"
            class="transparent-btn"
            v-preventReClick
          >
            创建点播
          </vh-dropdown-item>
          <vh-dropdown-item
            v-if="isTiming == 1"
            size="medium"
            round
            command="3"
            class="transparent-btn"
            v-preventReClick
          >
            创建定时直播
          </vh-dropdown-item>
        </vh-dropdown-menu>
      </vh-dropdown>
      <span v-else>
        <vh-button
          type="primary"
          round
          @click="createLiveAction('1')"
          v-preventReClick
          size="medium"
          class="length104"
        >
          创建直播
        </vh-button>
        <vh-button
          size="medium"
          round
          @click="createLiveAction('2')"
          v-if="vodPerssion == 1"
          class="transparent-btn"
          v-preventReClick
        >
          创建点播
        </vh-button>
        <vh-button
          v-if="isTiming == 1"
          size="medium"
          round
          @click="createLiveAction('3')"
          class="transparent-btn"
          v-preventReClick
        >
          创建定时直播
        </vh-button>
      </span>
      <div class="searchBox search-tag-box">
        <vh-select
          class="small"
          round
          v-model="modeType"
          placeholder="全部模式"
          @change="modeHandler"
        >
          <vh-option
            v-for="item in modeOptions"
            :key="item.value + item.label"
            :label="item.label"
            :value="item.value"
          ></vh-option>
        </vh-select>
        <vh-select
          round
          v-model="labelId"
          placeholder="全部标签"
          @change="labelHandler"
          class="tag_width"
        >
          <vh-option
            v-for="item in labelList"
            :key="item.label_id"
            :label="item.name"
            :value="item.label_id"
          ></vh-option>
        </vh-select>
        <vh-select
          class="small"
          round
          v-model="liveStatus"
          placeholder="全部状态"
          @change="liveHandler"
        >
          <vh-option
            v-for="item in statusOptions"
            :key="item.value + item.label"
            :label="item.label"
            :value="item.value"
          ></vh-option>
        </vh-select>
        <vh-select round v-model="orderBy" placeholder="请选择" @change="orderHandler">
          <vh-option
            v-for="item in orderOptions"
            :key="item.value + item.label"
            :label="item.label"
            :value="item.value"
          ></vh-option>
        </vh-select>
        <vh-input
          round
          style="width: 250px"
          class="search-tag"
          placeholder="请输入直播标题或者直播ID"
          v-model="keyWords"
          clearable
          v-clearEmoij
          @change="searchHandler"
          @keyup.enter.native="searchHandler"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchHandler"></i>
        </vh-input>
      </div>
    </div>
    <!-- 操作栏 -->
    <div v-if="totalElement">
      <vh-row :gutter="24" class="lives">
        <vh-col
          class="liveItem"
          :xs="8"
          :sm="8"
          :md="8"
          :lg="8"
          :xl="6"
          v-for="(item, index) in liveList"
          :key="index"
        >
          <!-- :xs="24" :sm="12" :md="12" :lg="8" :xl="6" -->
          <div @click="toLiveDetail(item.webinar_id)" class="inner">
            <!--  @click.prevent.stop="toDetail(item.webinar_id)" -->
            <div class="top">
              <span class="liveTag">
                <label class="live-status" v-if="item.webinar_state == 1">
                  <img src="../../common/images/live.gif" alt="" />
                </label>
                {{ item | liveTag }}
                <span
                  v-if="
                    item.is_new_version == 3 &&
                    (item.webinar_type == 3 || item.webinar_type == 6) &&
                    item.zdy_inav_num > 1
                  "
                >
                  | 1v{{ Number(item.inav_num) - 1 }}
                </span>
                <span v-if="item.webinar_type != 6 && isDelay && item.no_delay_webinar == 1">
                  | 无延迟
                </span>
                <span v-if="webinarDirector && item.is_director === 1">| 云导播</span>
                <span v-if="item.webinar_show_type == 0">| 竖屏</span>
              </span>
              <span class="hot">
                <i class="iconfont-v3 saasicon_redu">{{ item.pv | formatNum }}</i>
              </span>
              <div class="img-box">
                <img
                  :class="`img_box_bg webinar_bg_${item.itemMode}`"
                  :src="`${item.img_url}`"
                  alt=""
                />
              </div>
              <!-- <div class=""></div> -->
            </div>
            <div class="bottom">
              <div class="live-title">
                <p class="liveTitle">{{ item.subject }}</p>
                <p class="liveTime">{{ item.start_time }}</p>
              </div>
              <p class="liveOpera" @click.stop="nullFunc">
                <vh-tooltip
                  class="item"
                  effect="dark"
                  content="开播"
                  placement="top"
                  v-if="item.webinar_state != 4 && item.webinar_type != 5 && !isEmbed"
                  v-tooltipMove
                >
                  <i class="iconfont-v3 saasicon_kaibo" @click.prevent.stop="goLivePlay(item)"></i>
                  <!-- <router-link :to="`chooseWay/${item.webinar_id}/1`" target="_blank"><i class="vh-icon-video-camera"></i></router-link> -->
                </vh-tooltip>
                <vh-tooltip
                  v-tooltipMove
                  class="item"
                  effect="dark"
                  content="回放"
                  placement="top"
                  v-if="!(childPremission && Number(childPremission.permission_content) === 0)"
                >
                  <i
                    class="iconfont-v3 saasicon_huifang"
                    @click.prevent.stop="goPlayback(item)"
                  ></i>
                </vh-tooltip>
                <vh-tooltip v-tooltipMove class="item" effect="dark" content="详情" placement="top">
                  <i
                    class="iconfont-v3 saasicon_xiangqing"
                    @click.prevent.stop="toDetail(item.webinar_id)"
                  ></i>
                </vh-tooltip>
                <!-- isTiming == 1 -->
                <vh-tooltip
                  v-tooltipMove
                  class="item"
                  effect="dark"
                  content="复制"
                  placement="top"
                  v-if="item.webinar_type == 5 ? isTiming == 1 : item.webinar_state != 4"
                >
                  <i
                    class="iconfont-v3 saasicon_copy1"
                    @click.prevent.stop="toCopy(item.webinar_id, item.webinar_show_type, item)"
                  ></i>
                </vh-tooltip>
                <span @click.prevent.stop>
                  <vh-dropdown
                    :class="{ active: !!item.liveDropDownVisible }"
                    trigger="click"
                    placement="top-end"
                    @visible-change="dropDownVisibleChange(item)"
                    @command="commandMethod"
                  >
                    <i class="iconfont-v3 saasicon_more2"></i>
                    <vh-dropdown-menu style="width: 98px; padding: 4px 0" slot="dropdown">
                      <vh-dropdown-item
                        command="/live/reportsData"
                        v-if="!(childPremission && Number(childPremission.permission_data) === 0)"
                      >
                        数据报告
                      </vh-dropdown-item>
                      <vh-dropdown-item
                        command="/live/interactionData"
                        v-if="!(childPremission && Number(childPremission.permission_data) === 0)"
                      >
                        互动统计
                      </vh-dropdown-item>
                      <vh-dropdown-item
                        command="/live/userData"
                        v-if="!(childPremission && Number(childPremission.permission_data) === 0)"
                      >
                        用户统计
                      </vh-dropdown-item>
                      <!-- <vh-dropdown-item command='/live/edit' v-if="item.webinar_state!=4">复制</vh-dropdown-item> -->
                      <vh-dropdown-item command="删除">删除</vh-dropdown-item>
                    </vh-dropdown-menu>
                  </vh-dropdown>
                </span>
              </p>
            </div>
            <transition name="vh-zoom-in-bottom">
              <div class="mask" v-show="!!item.liveDropDownVisible"></div>
            </transition>
          </div>
        </vh-col>
      </vh-row>
      <SPagination
        :total="totalElement"
        :page-size="pageSize"
        :current-page="pageNum"
        :pageSizes="[12, 24, 36, 48, 60]"
        @current-change="currentChangeHandler"
        @size-change="sizeChange"
      ></SPagination>
    </div>
    <div class="no-live" v-if="!totalElement && !loading">
      <noData :nullType="nullText" :text="text">
        <vh-button
          type="primary"
          v-if="nullText == 'nullData'"
          round
          @click="createLiveAction('1')"
          v-preventReClick
          class="length152"
        >
          创建直播
        </vh-button>
        <vh-button
          round
          v-if="nullText == 'nullData' && vodPerssion == 1"
          class="transparent-btn length152"
          @click="createLiveAction('2')"
          v-preventReClick
        >
          创建点播
        </vh-button>
        <vh-button
          v-if="isTiming == 1"
          round
          @click="createLiveAction('3')"
          class="transparent-btn length152"
          v-preventReClick
        >
          创建定时直播
        </vh-button>
      </noData>
    </div>

    <!-- 绑定邮箱/手机号/修改密码 -->
    <ValidSetDialog ref="validSetDialog" @changeOk="changeOkHandle"></ValidSetDialog>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal, parseQueryString, jump, isEmbed } from '@/utils/utils';
  import ValidSetDialog from '@/views/AccountModule/components/validSetDialog.vue';
  export default {
    data() {
      return {
        isEmbed: isEmbed(),
        lowerGradeInterval: null,
        liveStatus: 0,
        nullText: 'nullData',
        orderBy: 1,
        isSearch: false, //是否是搜索
        text: '您还没有直播，快来创建吧！',
        keyWords: '',
        pageSize: 12,
        pageNum: 1,
        pagePos: 0,
        userId: '',
        isAnginOpen: false,
        webinarInfo: {},
        totalElement: 0,
        liveDropDownVisible: false,
        statusOptions: [
          { label: '全部状态', value: 0 },
          { label: '预告', value: 2 },
          { label: '直播', value: 1 },
          { label: '结束', value: 3 },
          { label: '点播', value: 4 },
          { label: '回放', value: 5 }
        ],
        orderOptions: [
          { label: '按创建时间排序', value: 1 },
          { label: '按最后直播时间排序', value: 2 }
        ],
        modeOptions: [
          { label: '全部模式', value: undefined },
          { label: '视频直播', value: 2 },
          { label: '互动直播', value: 3 },
          { label: '音频直播', value: 1 },
          { label: '分组直播', value: 6 },
          { label: '定时直播', value: 5 }
        ],
        loading: true,
        liveList: [],
        isTiming: 0, //是否有定时直播权限
        labelList: [],
        labelId: '',
        modeType: undefined
      };
    },
    computed: {
      childPremission: function () {
        return sessionOrLocal.get('SAAS_V3_SON_PS')
          ? JSON.parse(sessionOrLocal.get('SAAS_V3_SON_PS'))
          : {};
      },
      // admin无云导播活动权限
      webinarDirector() {
        //  webinar.director 1:有无延迟权限  0:无权限
        if (
          JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'))['webinar.director'] == '1'
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    components: {
      ValidSetDialog,
      PageTitle,
      noData
    },
    created() {
      // 创建点播是否可用(全局)
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      const SAAS_VS_PES = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'));
      this.vodPerssion = SAAS_VS_PES['ui.upload_video_as_demand'];
      this.isTiming = SAAS_VS_PES['webinar.timing'];
      this.getLiveList();
      const permission = SAAS_VS_PES ? SAAS_VS_PES['no.delay.webinar'] : 0;
      this.isDelay = permission == 1 ? true : false;
      console.log('>>>>>>>10', this.isDelay);
      this.getLabelList();
    },
    beforeDestroy() {
      if (this.lowerGradeInterval) clearInterval(this.lowerGradeInterval);
    },
    methods: {
      // 获取全部标签
      getLabelList() {
        this.$fetch('labelList', {
          webinar_id: this.keyWords,
          pos: 0,
          limit: 100
        }).then(res => {
          if (res.code == 200) {
            this.labelList = res.data.list;
            this.labelList.unshift({
              name: '全部标签',
              label_id: ''
            });
          }
        });
      },
      // 选择标签
      labelHandler() {
        this.searchHandler();
        if (this.liveStatus) {
          let livesType = [100050, 100049, 100051, 100052, 100053];
          this.$vhall_paas_port({
            k: livesType[this.liveStatus - 1],
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
      },
      handleLowerGradeHeart() {
        this.getLowerGradeConfig();
      },
      getPermission(id, isPortraitScreen, item) {
        // 活动权限
        this.$fetch('planFunctionGet', {
          webinar_id: id,
          webinar_user_id: this.userId,
          scene_id: 1
        })
          .then(res => {
            if (res.code == 200) {
              let arr = [
                'component_1',
                'component_2',
                'component_3',
                'component_4',
                'component_5',
                'component_6',
                'component_7',
                'component_8',
                'component_9'
              ];
              if (res.data.permissions) {
                sessionOrLocal.set('WEBINAR_PES', res.data.permissions, 'localStorage');
                this.perssionInfo = JSON.parse(sessionOrLocal.get('WEBINAR_PES', 'localStorage'));
                console.log(this.perssionInfo, '>>>>>>1231<<<');
                this.isShow = true;
                this.isTrue = arr.some(item => {
                  // eslint-disable-next-line no-prototype-builtins
                  return this.perssionInfo[item] > 0;
                });
                this.hasDelayPermission =
                  this.perssionInfo['no.delay.webinar'] &&
                  this.perssionInfo['no.delay.webinar'] == 1
                    ? true
                    : false;
                const hasPortraitScreen =
                  this.perssionInfo['portrait_screen'] && this.perssionInfo['portrait_screen'] == 1
                    ? true
                    : false;
                // this.handleLowerGradeHeart()
                //  portrait_screen 是否支持竖屏 1:开启 0:关闭。
                if (!hasPortraitScreen && isPortraitScreen == 0) {
                  this.$vhMessage({
                    message: '本活动为竖屏直播，暂无竖屏直播权限，请联系客服开通',
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                  return;
                }
              } else {
                sessionOrLocal.removeItem('WEBINAR_PES');
              }
              const jumpPath = item.webinar_type == 5 ? 'timeEdit' : 'edit';
              jump({
                path: `${isEmbed() ? '/embed' : ''}/live/${jumpPath}`,
                query: { id: id, type: 3 }
              });
            }
          })
          .catch(e => {
            console.log(e);
            sessionOrLocal.removeItem('SAAS_VS_PES');
          });
      },
      getLowerGradeConfig(id) {
        this.$fetch('lowerGrade', {})
          .then(res => {})
          .catch(res => {
            // 降级没有code吗
            const { activity, user, global } = res;
            // 优先顺序：互动 > 用户 > 全局
            const activityConfig =
              activity && activity.length > 0
                ? activity.find(option => option.audience_id == id)
                : null;
            const userConfig =
              user && user.length > 0
                ? user.find(option => option.audience_id == this.userId)
                : null;
            if (activityConfig) {
              this.setLowerGradeConfig(activityConfig.permissions);
            } else if (userConfig) {
              this.setLowerGradeConfig(userConfig.permissions);
            } else if (global && global.permissions) {
              this.setLowerGradeConfig(global.permissions);
            }
          });
      },
      setLowerGradeConfig(data) {
        console.log(data, 'data');
        if (this.lowerGradeInterval) clearInterval(this.lowerGradeInterval);
        let arr = [
          'component_1',
          'component_2',
          'component_3',
          'component_4',
          'component_5',
          'component_6',
          'component_7',
          'component_8',
          'component_9'
        ];
        let perssionInfo = JSON.parse(sessionOrLocal.get('WEBINAR_PES', 'localStorage'));
        perssionInfo = perssionInfo ? Object.assign(perssionInfo, data) : data;
        this.perssionInfo = perssionInfo;
        sessionOrLocal.set('WEBINAR_PES', perssionInfo, 'localStorage');
        if (this.lowerGradeInterval) clearInterval(this.lowerGradeInterval);
        // const SAAS_VS_PES = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'))

        this.vodPerssion = perssionInfo['ui.upload_video_as_demand'];
        this.isTiming = perssionInfo['webinar.timing'];
        const permission = perssionInfo ? perssionInfo['no.delay.webinar'] : 0;
        this.isDelay = permission == 1 ? true : false;
      },
      toLiveDetail(webinar_id) {
        this.$vhall_paas_port({
          k: 100046,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: webinar_id,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        jump({
          path: `${isEmbed() ? '/embed' : ''}/live/detail/${webinar_id}`
        });
      },
      nullFunc() {
        return false;
      },
      modeHandler() {
        this.searchHandler();
      },
      liveHandler() {
        this.searchHandler();
        if (this.liveStatus) {
          let livesType = [100050, 100049, 100051, 100052, 100053];
          this.$vhall_paas_port({
            k: livesType[this.liveStatus - 1],
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
      },
      orderHandler() {
        this.searchHandler();
        this.$vhall_paas_port({
          k: this.orderBy == 1 ? 100047 : 100048,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
      },
      searchHandler() {
        this.pageNum = 1;
        this.pagePos = 0;
        this.getLiveList();
      },
      dropDownVisibleChange(item) {
        this.$set(item, 'liveDropDownVisible', !item.liveDropDownVisible);
        this.webinarInfo = item;
      },
      commandMethod(command) {
        if (command === '删除') {
          if (this.webinarInfo.webinar_state == 1) {
            this.$vhMessage({
              message: '正在直播的活动不能删除',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            return;
          }
          this.$vhConfirm('删除直播后，直播也将从所属的专题中删除，确定要删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            lockScroll: false,
            roundButton: true,
            cancelButtonClass: 'zdy-confirm-cancel'
          })
            .then(() => {
              this.deleteLive();
            })
            .catch(() => {
              this.$vhMessage({
                message: `已取消删除`,
                showClose: true,
                // duration: 0,
                type: 'info',
                customClass: 'zdy-info-box'
              });
            });
        } else {
          let num =
            command === '/live/reportsData'
              ? 100042
              : command === '/live/interactionData'
              ? 100043
              : 100044;
          // 新标签页打开
          // this.$router.push({path: `${command}/${this.webinarInfo.webinar_id}`, query: {roomId: this.webinarInfo.vss_room_id, status: this.webinarInfo.webinar_state }});
          this.$vhall_paas_port({
            k: num,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.webinarInfo.webinar_id,
              s: '',
              refer: 1,
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          jump({
            path: `${isEmbed() ? '/embed' : ''}${command}/${this.webinarInfo.webinar_id}`,
            query: { roomId: this.webinarInfo.vss_room_id, status: this.webinarInfo.webinar_state }
          });
        }
      },
      currentChangeHandler(current) {
        this.pageNum = current;
        this.pagePos = parseInt((current - 1) * this.pageSize);
        this.getLiveList();
      },
      sizeChange(current) {
        this.pageNum = 1;
        this.pageSize = current;
        this.pagePos = 0;
        this.getLiveList();
      },
      // 解析图片地址
      handlerImageInfo(url) {
        let obj = parseQueryString(url);
        return Number(obj.mode) || 3;
      },
      getLiveList() {
        let data = {
          pos: this.pagePos,
          limit: this.pageSize,
          order_type: this.orderBy,
          title: this.keyWords,
          webinar_state: this.liveStatus,
          label_id: this.labelId,
          webinar_type: this.modeType
        };
        this.loading = true;
        this.$fetch('liveList', this.$params(data))
          .then(res => {
            const liveList = res.data.list;
            liveList.map(item => {
              // 非化蝶活动，若超过1v5，默认展示1v5
              item.zdy_inav_num = item.is_new_version != 3 && item.inav_num > 6 ? 6 : item.inav_num;
              item.itemMode = this.handlerImageInfo(item.img_url);
            });
            this.liveList = liveList;
            this.totalElement = res.data.total;
            // 搜索状态
            this.nullText = 'search';
            this.text = '';
            this.isSearch = true;
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '获取直播列表失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            console.log(res);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      deleteLive() {
        this.$fetch('liveDel', { webinar_ids: this.webinarInfo.webinar_id })
          .then(res => {
            this.$vhall_paas_port({
              k: 100045,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: this.webinarInfo.webinar_id,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `删除成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            // 刷新列表
            this.searchHandler();
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `删除失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      getAppersInfo(item) {
        this.$fetch('getVersionInfo', { user_id: this.userId })
          .then(res => {
            if (res.data.arrears.total_fee > 0) {
              this.$vhConfirm(
                `尊敬的微吼会员，您的${
                  res.data.type == 1 ? '流量' : res.data.type == 2 ? '时长资源' : '并发套餐'
                }已用尽，${res.data.type == 2 ? '请联系客服充值' : '请充值'}`,
                '提示',
                {
                  confirmButtonText: res.data.type == 2 ? '知道了' : '去充值',
                  cancelButtonText: '知道了',
                  customClass: 'zdy-message-box',
                  lockScroll: false,
                  confirmButtonPosition: 'left',
                  roundButton: true,
                  showCancelButton: res.data.type == 2 ? false : true,
                  cancelButtonClass: 'zdy-confirm-cancel'
                }
              )
                .then(() => {
                  if (res.data.type == 2) return;
                  this.$router.push({ path: '/finance/info' });
                })
                .catch(() => {});
            } else {
              if (item.webinar_state == 1) {
                this.getOpenLive(item);
              } else {
                this.goIsLive(item);
              }
            }
          })
          .catch(e => {
            console.log(e);
          });
      },
      goLivePlay(item) {
        //判断是否可以开播
        this.getAppersInfo(item);
      },
      goPlayback(item) {
        this.$vhall_paas_port({
          k: 100039,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: item.webinar_id,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        jump({
          path:
            item.webinar_state == 4
              ? `${isEmbed() ? '/embed' : ''}/live/recordplayback/${item.webinar_id}`
              : `${isEmbed() ? '/embed' : ''}/live/playback/${item.webinar_id}?type=${
                  item.webinar_type
                }`
        });
      },
      goIsLive(item) {
        this.$vhall_paas_port({
          k: 100038,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: item.webinar_id,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        if (item.webinar_type != 1) {
          const { href } = this.$router.resolve({
            path: `/live/chooseWay/${item.webinar_id}/1?type=ctrl`
          });
          window.open(href, '_blank');
        } else {
          let href = `${window.location.origin}${process.env.VUE_APP_WEB_KEY}/lives/room/${item.webinar_id}`;
          window.open(href, '_blank');
        }
      },
      // 判断是否有起直播的权限
      getOpenLive(item) {
        this.$fetch(
          'checkLive',
          this.$params({
            webinar_id: item.webinar_id
          })
        )
          .then(res => {
            if (res.code == 200) {
              this.goIsLive(item);
            } else {
              // 不能发起
              this.$vhMessage({
                message: '该活动正在直播或录播中，无法重复发起',
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              return;
            }
          })
          .catch(e => {
            this.$vhMessage({
              message: '该活动正在直播或录播中，无法重复发起',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            return;
          });
      },
      // 创建活动
      createLiveAction(index) {
        console.log('账号下最多创建100个直播标签');
        let userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
        if (!userInfo.phone && userInfo.user_extends.extends_remark != 2) {
          this.$vhAlert('您还没有绑定手机，为了保证您的权益，请绑定后再发起直播！', '提示', {
            confirmButtonText: '立即绑定',
            customClass: 'zdy-message-box',
            lockScroll: false,
            cancelButtonClass: 'zdy-confirm-cancel',
            callback: action => {
              if (action === 'confirm') {
                if (this.isEmbed) {
                  this.$nextTick(() => {
                    this.currentCreateLiveActionIndex = index;
                    this.$refs.validSetDialog.initComp({}, 'phone');
                  });
                  return true;
                }
                this.$router.push({ path: '/acc/info', query: { tab: 1 } });
              }
            }
          });
        } else {
          let url = index == 1 ? '/live/edit' : index == 2 ? '/live/vodEdit' : '/live/timeEdit';
          this.$router.push({ path: `${isEmbed() ? '/embed' : ''}${url}`, query: { refer: 3 } });
        }
      },
      toDetail(id, state) {
        this.$vhall_paas_port({
          k: 100040,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: id,
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        jump({
          path: `${isEmbed() ? '/embed' : ''}/live/detail/${id}`
        });
      },
      toRoom(id) {
        const { href } = this.$router.resolve({ path: `/lives/room/${id}` });
        window.open(href);
      },
      toCopy(id, isPortraitScreen, item) {
        this.$vhConfirm('支持复制活动下设置的功能，不支持复制回放视频、统计的数据', '复制活动', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,
          confirmButtonPosition: 'right',
          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.$vhall_paas_port({
              k: 100041,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: id,
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.getPermission(id, isPortraitScreen, item);
          })
          .catch(() => {});
      },
      changeOkHandle() {
        // 保存成功，数据更新
        this.$fetch('getInfo', {
          scene_id: 2
        })
          .then(res => {
            sessionOrLocal.set('userInfo', JSON.stringify(res.data));
            sessionOrLocal.set('userId', JSON.stringify(res.data.user_id));
            this.$EventBus.$emit('saas_vs_account_change', res.data);
            this.createLiveAction(this.currentCreateLiveActionIndex);
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `获取账户信息失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      }
    }
  };
</script>
<style lang="less" scoped>
  .pageTitle {
    color: #1a1a1a;
    font-size: 22px;
    font-weight: 600;
  }
  .operaBox {
    overflow: hidden;
    margin-top: 12px;
    margin-bottom: 20px;
    .searchBox {
      float: right;
      .vh-select {
        width: 150px;
        margin-right: 10px;
      }
      .small {
        width: 110px;
      }
    }
  }
  .lives {
    .liveItem {
      height: 314px;
      margin-bottom: 24px;
      .inner {
        transition: all 0.15s ease-in;
        position: relative;
        border-radius: 4px;
        display: inline-block;
        width: 100%;
        cursor: pointer;
      }
      .inner:hover {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
        border-radius: 4px;
      }
      .top {
        height: 176.35px;
        background: #1a1a1a;
        padding: 10px 10px;
        box-sizing: border-box;
        position: relative;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        .img-box {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 4px 4px 0 0;
          overflow: hidden;
          .img_box_bg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            cursor: pointer;
            font-size: 0;
            &.webinar_bg_1 {
              object-fit: fill;
            }
            &.webinar_bg_2 {
              object-fit: cover;
              object-position: left top;
            }
          }
        }

        .liveTag {
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
          font-size: 12px;
          padding: 3px 8px;
          border-radius: 20px;
          position: relative;
          z-index: 2;
        }
        .live-status {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin: 0 6px 0 2px;
          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
        .hot {
          position: absolute;
          height: 50px;
          width: 100%;
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
          bottom: 0px;
          left: 0px;
          color: #fff;
          font-size: 14px;
          z-index: 2;
          cursor: pointer;
          i {
            position: absolute;
            left: 14px;
            bottom: 10px;
          }
        }
      }
      .bottom {
        height: 139px;
        background: #fff;
        box-sizing: border-box;
        padding: 14px 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 0 0 4px 4px;
        .liveTitle {
          color: #1a1a1a;
          font-size: 16px;
          margin-bottom: 8px;
          line-height: 24px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .liveTime {
          font-size: 14px;
          color: #666;
        }
        .liveOpera {
          color: #666666;
          font-size: 18px;
          cursor: default;
          a {
            color: rgb(44, 43, 43);
          }
          i {
            cursor: pointer;
            &:not(:nth-child(4)) {
              margin-right: 20px;
            }
          }
          /deep/.iconfont-v3 {
            font-size: 18px;
          }
          .vh-dropdown {
            float: right;
            i {
              margin-right: 0;
            }
            &.active {
              z-index: 2;
            }
          }
          .item {
            &:hover {
              color: #222;
            }
          }
          .vh-dropdown :hover {
            color: #222;
          }
        }
      }
    }
  }
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .liveListBox {
    margin: auto;
    width: 1020px;
  }
  @media screen and (min-width: 1920px) {
    .liveListBox {
      margin: auto;
      width: 1374px;
    }
  }
</style>
<style lang="less">
  .vh-tooltip__popper {
    max-width: 100%;
  }
  .dropdown_width {
    .length104 {
      width: 147px;
    }
    .vh-dropdown-menu__item {
      text-align: left;
    }
  }
</style>
<style lang="css">
  html {
    background: #f7f7f7;
  }
</style>
